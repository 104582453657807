import { Flex, Spinner, Text } from "@chakra-ui/react"
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { FilterLabel } from "@/components/calendar/filters/Label";
import { RoomStatusContainer, RoomStatusViewType } from "@/components/rooms/status/RoomStatusContainer";
import { RoomStatusFilter } from "@/components/rooms/status/RoomStatusFilter";
import { RoomStatusMenu } from "@/components/rooms/status/RoomStatusMenu";
import { IOptionGen } from "@/components/ui-elements/Select/types";
import { useSearchCohortsQuery } from "@/store/services/users";

export function RoomsDisponibility() {
  const maxElems = 20
  const { t } = useTranslation('components/rooms/cards');
  const [ searchParams, setSearchParams ] = useSearchParams();

  const fromParam = searchParams.get("start");
  const toParam = searchParams.get("end");
  const cityParam = searchParams.get("city");
  const viewParam = searchParams.get("view") || "grid";
  const roomsParam = searchParams.get("rooms");

  const {data: cohorts, isLoading, error} = useSearchCohortsQuery(
    {type: "city", limit: maxElems},
  );

  const defaultCity: IOptionGen<string> | undefined = useMemo(() => {
    const currentCityLabel = cohorts?.filter((el) => (el.name === cityParam))
    if (!currentCityLabel || currentCityLabel?.length <= 0) {
      return;
    }
    return {label: currentCityLabel[0].name, value: currentCityLabel[0]._id} as IOptionGen<string>
  }, [cityParam, cohorts])

  const onViewChange = useCallback((newValue: RoomStatusViewType) => {
    setSearchParams((prev) => {
      prev.set("view", newValue);
      return prev;
    });
  }, [setSearchParams]);

  return (
    <Flex flexDirection="column" background="secondBgColor" borderRadius={4} p={4}>
      <Flex flexDirection="row" justifyContent="space-between">
        <FilterLabel label="RoomStatus"/>
        <RoomStatusMenu view={viewParam as RoomStatusViewType} onViewChange={onViewChange}/>
      </Flex>

      {cohorts && <RoomStatusFilter
        city={defaultCity}
        cohorts={cohorts}
      />}
      {
        isLoading && <Spinner mb={4} alignSelf="center"/>
      }
      {
        !isLoading && error && <Text mb={4} display="flex" justifyContent="center">{t("error")}</Text>
      }
      {
        !isLoading && !error && defaultCity && fromParam && toParam &&
        <RoomStatusContainer 
          roomsFilter={roomsParam?.split(',') || []}
          view={viewParam as RoomStatusViewType}
          cityId={defaultCity?.value}
          start={fromParam} 
          end={toParam} 
          maxElems={maxElems}
        />
      }
      {
        !isLoading && !error && !cityParam && <Text mb={4} display="flex" justifyContent="center">{t("no-city_selected")}</Text>
      }
    </Flex>
  );
}