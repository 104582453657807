import { Text, TextProps } from "@chakra-ui/react";
import { PartialRoomEvent } from "@epitech/ops-panoramix-events-types";
import { formatDate } from "@fullcalendar/core";
import React from "react";
import { useTranslation } from "react-i18next";

interface RoomStatusTextProps extends TextProps {
  isOccupied: boolean;
  currentEvent: PartialRoomEvent;
  nextEvent: PartialRoomEvent | null;
  locale: string | undefined;
}

function _RoomStatusText({isOccupied, currentEvent, nextEvent, locale, ...props}: RoomStatusTextProps) {
  const { t } = useTranslation("components/rooms/cards")

  return (
    <Text 
    flex="1"
    color={isOccupied ? "errorStatusBadgeFgColor" : "warningStatusFgColor"}
    {...props}
    >
      {t('until')}&nbsp;
      {
      isOccupied ?
        <>
        {
          formatDate(currentEvent.end, {
            locale: locale,
            hour: '2-digit',
            minute: '2-digit',
          })
        }&nbsp;
        {
          formatDate(currentEvent.end, {
            day: '2-digit',
            month: '2-digit',
            locale: locale,
          })
        }
        </>
      : 
      <>
        {
          formatDate(!nextEvent ? currentEvent.start : nextEvent.start, {
            locale: locale,
            hour: '2-digit',
            minute: '2-digit',
          })
        }&nbsp;
        {
          formatDate(!nextEvent ? currentEvent.end : nextEvent.end, {
            day: '2-digit',
            month: '2-digit',
            locale: locale,
          })
        }
        </>
      }
    </Text>
  );
}

export const RoomStatusText = React.memo(_RoomStatusText);
