import { ActivityRef, ITpIdSource, ProjectGroupRef } from '@epitech/ops-panoramix-types';
import { ComponentType, lazy } from 'react';
import { createSearchParams } from 'react-router-dom';

import { PANORAMIX_ROUTES } from '@/config/constants';
import { environment } from '@/config/environment';
import {
  DEFAULT_CALENDAR_PARAMS,
  DEFAULT_ROOM_DISPONIBILITY_PARAMS,
  DEFAULT_ROOMS_PARAMS,
} from '@/pages/Calendar';

export const preventParentClickHandler = (e: React.MouseEvent) => {
  e.stopPropagation();
};

export function getSearchParams(
  path: (typeof PANORAMIX_ROUTES)[number] | undefined,
): URLSearchParams {
  if (!path) {
    return new URLSearchParams('');
  }
  const choices = {
    calendar: createSearchParams(DEFAULT_CALENDAR_PARAMS),
    'rooms/timeline': createSearchParams(DEFAULT_ROOMS_PARAMS),
    modules: new URLSearchParams(''),
    'rooms/status': createSearchParams(DEFAULT_ROOM_DISPONIBILITY_PARAMS),
  };
  return choices[path];
}

export const getExternalLinkFromSource = (
  source: ITpIdSource,
  append: string = '',
): string | undefined => {
  if (environment.services.SOURCE_URI[source]) {
    return `${environment.services.SOURCE_URI[source]}/${append}`;
  }
};

export const getMoodleLinkToCourse = (
  source: ITpIdSource,
  courseId: string,
): string | undefined => {
  return getExternalLinkFromSource(source, `course/view.php?id=${courseId}`);
};

export const getMoodleLinkToActivity = (
  source: ITpIdSource,
  activity: ActivityRef,
  projectGroupRef?: ProjectGroupRef,
): string | undefined => {
  if (activity.tpIds.length === 0) {
    return;
  }

  let groupParam = '';

  if (projectGroupRef && projectGroupRef.tpIds) {
    const tpIdMatch = projectGroupRef.tpIds.find(tpId => tpId.source === source);
    if (tpIdMatch) {
      groupParam = `&group=${tpIdMatch.id}`;
    }
  }
  return getExternalLinkFromSource(
    source,
    `mod/${activity.tpIds[0].sourceType}/view.php?id=${activity.tpIds[0].id}${groupParam}`,
  );
};

export const debounce = <T>(limitChar: number, func: (search: string) => Promise<T[]>) => {
  return (search: string) => (search.length >= limitChar ? func(search) : Promise.all([]));
};

export const defineTitle = (
  appName: string,
  env: string,
  user: string = '',
  logas: boolean = false,
): string => {
  let title = '';

  if (user === '') title = `${appName} - Calendar management`;
  else title = `${appName} - ${logas ? '[Logas]' : ''} ${user}`;

  if (env === 'development') title = `[DEV] ${title}`;
  else if (env === 'preproduction') title = `[PPD] ${title}`;

  return title;
};

// function here only for testing purposes, allow to selay the loading of a component
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const lazyMinLoadTime = <T extends ComponentType<any>>(
  factory: () => Promise<{ default: T }>,
  minLoadTimeMs = 2000,
) =>
  lazy(() =>
    Promise.all([factory(), new Promise(resolve => setTimeout(resolve, minLoadTimeMs))]).then(
      ([moduleExports]) => moduleExports,
    ),
  );
