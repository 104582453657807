import { PartialRoomEvent } from '@epitech/ops-panoramix-events-types';
import { useMemo } from 'react';

export function useRoomStatus(events: PartialRoomEvent[], nowTimeStamp: number) {
  const { consecutiveEvents, onGoingIndex } = useMemo(() => {
    let onGoingIndex: number = -1;

    events.forEach((el, index) => {
      if (
        new Date(el.start).getTime() < nowTimeStamp &&
        new Date(el.end).getTime() > nowTimeStamp
      ) {
        onGoingIndex = index;
      }
    });

    if (onGoingIndex < 0) {
      return { consecutiveEvents: [], onGoingIndex: 0 };
    }

    const tmpEvent = events[onGoingIndex];
    const consecutive = events.slice(onGoingIndex).filter((el, index) => {
      return (
        new Date(el.start).getTime() === new Date(tmpEvent.end).getTime() ||
        (index - 1 > 0 &&
          new Date(el.start).getTime() === new Date(events[index - 1].end).getTime())
      );
    });
    consecutive.unshift(events[onGoingIndex]);

    return { consecutiveEvents: consecutive, onGoingIndex };
  }, [events, nowTimeStamp]);

  const isReserved = events && events?.length > 0;
  const isOccupied = onGoingIndex >= 0 && consecutiveEvents.length > 0;
  const onGoingEvent = events[onGoingIndex];

  return { isReserved, isOccupied, onGoingEvent, consecutiveEvents, onGoingIndex };
}
