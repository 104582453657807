import { useColorMode } from '@chakra-ui/react';
import { EventTimeline } from '@epitech/ops-panoramix-events-types/dist/events/validators/Timeline.validator';
import { Stored } from '@epitech/ops-panoramix-types';
import { useMemo } from 'react';
import { shallowEqual } from 'react-redux';

import { filterEvents, ICalendarFilters } from '@/components/calendar/filters/utils';
import { sanitizeDatePayload } from '@/lib/helpers/dates.helpers';
import { getAllDaySetter, getModuleColorMapper } from '@/lib/helpers/events.helpers';
import { useSelector } from '@/lib/hooks/useSelector';
import { useGetRoomsQuery, useSearchRoomEventsQuery } from '@/store/services/events';

import { IEventsFetcherProps } from './useEventsFetcher';

type EventTimeLineWithResourceId = EventTimeline & { resourceId: string };

export function useEventTimelineFetcher(
  { filters, start, end }: IEventsFetcherProps,
  skip = false,
) {
  const {
    data: rawEvents = [],
    isLoading: isEventsLoading,
    isFetching: isEventsFetching,
  } = useSearchRoomEventsQuery({ start, end }, { refetchOnMountOrArgChange: true, skip });
  const {
    data: rawRooms = [],
    isLoading,
    isFetching,
  } = useGetRoomsQuery({ limit: 0 }, { refetchOnMountOrArgChange: true, skip });
  const timelineFilters: Partial<ICalendarFilters> = useMemo(
    () => ({ modulesCode: filters.modulesCode, promotion: filters.promotion }),
    [filters],
  );
  const moduleColors = useSelector(state => state.settings.moduleColors, shallowEqual);
  const { colorMode } = useColorMode();

  const getModuleColor = useMemo(
    () => getModuleColorMapper(moduleColors, colorMode),
    [moduleColors, colorMode],
  );
  const setAllDay = useMemo(() => getAllDaySetter(start, end), [start, end]);

  const events = useMemo(() => {
    const eventsRoom = rawEvents.flatMap(event =>
      event.roomsRef.map(room =>
        sanitizeDatePayload<Stored<EventTimeLineWithResourceId>>({
          _id: event._id,
          start: event.start,
          end: event.end,
          title: event.title,
          roomsRef: event.roomsRef,
          moduleRef: event.moduleRef,
          cohortGroups: event.cohortGroups,
          resourceId: room._id,
          registrationType: event.registrationType,
          type: event.type,
          tpIds: event.tpIds,
        }),
      ),
    );
    return filterEvents(eventsRoom, timelineFilters).map(event => ({
      ...setAllDay(event),
      ...getModuleColor(event),
    }));
  }, [rawEvents, timelineFilters, getModuleColor, setAllDay]);

  const rooms = useMemo(() => {
    const currentRooms = rawRooms.map(rawRoom => ({
      ...rawRoom,
      id: rawRoom._id,
      title: rawRoom.name,
      cohortGroups: rawRoom.cohortGroups,
    }));
    if (filters.city) {
      return currentRooms.filter(
        room => room?.cohortGroups.some(cohort => cohort?.city?._id === filters.city),
      );
    }
    return currentRooms;
  }, [rawRooms, filters]);

  const loading = isLoading || isFetching || isEventsLoading || isEventsFetching;

  return {
    rooms,
    events,
    loading,
  };
}
