import type {
  ActivityCityEvents,
  EventEntry,
  EventRegisteredSample,
  EventSample,
  HistoryEntry,
  IEventFormInput,
  IPaginatedData,
  RoomEntry,
  RoomEvents,
  StoredEventEntry,
} from '@epitech/ops-panoramix-events-types';
import { EventTimeline } from '@epitech/ops-panoramix-events-types/dist/events/validators/Timeline.validator';
import {
  CohortGroup,
  EventRef,
  ModuleRef,
  ProjectGroupRef,
  RoomRef,
  Stored,
  UserRef,
} from '@epitech/ops-panoramix-types';
import { createApi } from '@reduxjs/toolkit/query/react';
import queryString from 'query-string';

import { environment } from '@/config/environment';
import { WithRawDates } from '@/lib/types/events';

import { customFetchQuery } from './baseQueryWithReauth';
import { DEFAULT_PAGINATION, IPaginatedRequest, providesList } from './helpers';

export interface IUnregisteredByEdit
  extends Omit<EventEntry, 'cohortGroups' | 'roomsRef' | 'outlookRef' | 'moduleRef' | 'slots'> {
  registeredGroups: ProjectGroupRef[];
  registeredUsers: UserRef[];
  registeredStaffs: UserRef[];
}

type BaseRegisterToEventArgs = {
  eventId: string;
  slotIdx?: number;
};

export interface ISanitizedPartialEvent extends Stored<EventTimeline> {
  allDay: boolean;
  color?: string;
}

export interface ISearchEventsRequest extends IPaginatedRequest {
  start?: string;
  end?: string;
  search?: string;
  moduleId?: string;
  cityId?: string;
  rooms?: string[];
  disabled?: boolean;
}

export interface ISearchRoomsRequest extends IPaginatedRequest {
  search?: string;
  city?: string;
  disabled?: boolean;
}

export interface ISearchRoomEventsRequest extends IPaginatedRequest {
  start?: string;
  end?: string;
  search?: string;
  disabled?: boolean;
}

export type RegisterTypes = 'users' | 'staffs' | 'projectGroups';
export type RegisterToEventArgs = BaseRegisterToEventArgs &
  (
    | {
        type: RegisterTypes;
        toRegister: UserRef | ProjectGroupRef;
      }
    | {
        type: Extract<RegisterTypes, 'users' | 'staffs'>;
        toRegister: UserRef;
      }
    | {
        type: Extract<RegisterTypes, 'projectGroups'>;
        toRegister: ProjectGroupRef;
      }
  );

export type ForceRegisterToEventArgs = Omit<BaseRegisterToEventArgs, 'slotIdx'> &
  (
    | {
        type: RegisterTypes;
        toRegisters: UserRef[] | ProjectGroupRef[] | (UserRef | ProjectGroupRef)[];
        slotIdx?: number | number[];
      }
    | {
        type: Extract<RegisterTypes, 'users'>;
        toRegisters: UserRef[];
        slotIdx?: number;
      }
    | {
        type: Extract<RegisterTypes, 'staffs'>;
        toRegisters: UserRef[];
        slotIdx?: number | number[];
      }
    | {
        type: Extract<RegisterTypes, 'projectGroups'>;
        toRegisters: ProjectGroupRef[];
        slotIdx?: number;
      }
  );

export interface ForceRegisterUsersWithMetadata extends Omit<BaseRegisterToEventArgs, 'slotIdx'> {
  cohortGroups?: CohortGroup[];
  moduleRef?: ModuleRef;
}

type MassUnregisterRoomFromSlotArgs = {
  eventId: string;
  slotIdx?: number[];
};

type UnregisterRoomFromSlotArgs = {
  eventId: string;
  slotIdx?: number;
};

type MassForceRegisterRoomsToEventArgs = {
  eventId: string;
  roomsToRegister: RoomRef[];
  slotIdx?: number[];
};

type ForceRegisterRoomsToEventArgs = {
  eventId: string;
  roomsToRegister: RoomRef[];
  slotIdx?: number;
};

type EventMutationArgs = {
  ignoreWarnings?: boolean;
} & IEventFormInput;

type DeleteEventMutationArgs = {
  ignoreWarnings?: boolean;
  eventId: string;
};

export const eventsApi = createApi({
  reducerPath: 'eventsApi',
  baseQuery: customFetchQuery(environment.services.EVENTS_URI),
  tagTypes: [
    'Event',
    'EventRef',
    'EventSample',
    'RoomRef',
    'Room',
    'EventHistory',
    'ActivityCityEvents',
    'RoomEvents',
  ],
  endpoints: builder => ({
    getEvents: builder.query<WithRawDates<EventSample>[], ISearchEventsRequest>({
      query: ({ start, end, search, limit, offset }) => {
        /** We do not want to limit when performing a search by dates */
        return queryString.stringifyUrl({
          url: '/events',
          query: {
            start,
            end,
            search,
            limit,
            offset,
          },
        });
      },
      providesTags: events => providesList(events, 'EventSample'),
    }),
    getEventsWithRegisteredStatus: builder.query<
      WithRawDates<EventRegisteredSample>[],
      ISearchEventsRequest
    >({
      query: ({ start, end, search, limit, offset }) => {
        /** We do not want to limit when performing a search by dates */
        return queryString.stringifyUrl({
          url: '/events',
          query: {
            start,
            end,
            search,
            registrationStatus: true,
            limit,
            offset,
          },
        });
      },
      providesTags: events => providesList(events, 'EventSample'),
    }),
    searchEventsWithModuleIds: builder.query<ActivityCityEvents[], ISearchEventsRequest>({
      query: ({ start, end, search, limit, offset, moduleId }) => {
        /** We do not want to limit when performing a search by dates */
        return queryString.stringifyUrl({
          url: `/events/module/${moduleId}`,
          query: {
            start,
            end,
            search,
            limit,
            offset,
          },
        });
      },
      providesTags: events => providesList(events, 'ActivityCityEvents'),
    }),
    searchEventsWithRoomsByCityId: builder.query<
      IPaginatedData<RoomEvents[]>,
      ISearchEventsRequest
    >({
      query: ({ start, end, search, limit, offset, cityId, rooms, disabled = false }) => {
        /** We do not want to limit when performing a search by dates */
        return queryString.stringifyUrl({
          url: `/events/rooms/${cityId}`,
          query: {
            start,
            end,
            search,
            limit,
            offset,
            rooms,
            disabled,
            isTime: true,
          },
        });
      },
      providesTags: events => providesList(events?.data, 'RoomEvents'),
    }),
    /** Different method as this return `EventsRef` and not full events */
    searchEvents: builder.query<WithRawDates<EventRef>[], ISearchEventsRequest>({
      query: ({
        start,
        end,
        search,
        limit = DEFAULT_PAGINATION['limit'],
        offset = DEFAULT_PAGINATION['offset'],
      }) =>
        queryString.stringifyUrl({
          url: '/events',
          query: {
            start,
            end,
            search,
            limit,
            offset,
            refOnly: true,
          },
        }),
      providesTags: events => providesList(events, 'EventRef'),
    }),
    getEvent: builder.query<WithRawDates<StoredEventEntry>, string>({
      query: eventId => `/events/${eventId}`,
      providesTags: event => [{ type: 'Event', id: event?._id }],
      transformResponse: (response: WithRawDates<StoredEventEntry>) => {
        if (response.type === 'course') {
          response.registeredUsers?.sort((a, b) => a.login.localeCompare(b.login));
          response.registeredStaffs?.sort((a, b) => a.login.localeCompare(b.login));
          response.registeredGroups?.sort((a, b) => a.name.localeCompare(b.name));
        } else if (response.type === 'appointment') {
          response.slots?.forEach(slot => {
            slot.registeredStaffs.sort((a, b) => a.login.localeCompare(b.login));
            slot.registeredGroup?.usersRef.sort((a, b) => a.login.localeCompare(b.login));
          });
        }
        return response;
      },
    }),
    getEventHistory: builder.query<WithRawDates<HistoryEntry>[], string>({
      query: eventId => `/events/${eventId}/history`,
      providesTags: (_history, _err, eventId) => [{ type: 'EventHistory', id: eventId }],
    }),
    createEvent: builder.mutation<WithRawDates<StoredEventEntry>, EventMutationArgs>({
      query: body => ({
        url: queryString.stringifyUrl({
          url: '/events',
          query: { ignoreWarnings: body.ignoreWarnings || false },
        }),
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        { type: 'Event', id: 'LIST' },
        { type: 'EventSample', id: 'LIST' },
      ],
    }),
    updateEvent: builder.mutation<WithRawDates<StoredEventEntry>, EventMutationArgs>({
      query: patch => ({
        url: queryString.stringifyUrl({
          url: `/events/${patch._id}`,
          query: { ignoreWarnings: patch.ignoreWarnings || false },
        }),
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: event =>
        event
          ? [
              { type: 'Event', id: event?._id },
              { type: 'EventSample', id: event?._id },
              { type: 'EventHistory', id: event?._id },
            ]
          : [],
    }),
    deleteEvent: builder.mutation<void, DeleteEventMutationArgs>({
      query: ({ eventId, ignoreWarnings = false }) => ({
        url: queryString.stringifyUrl({
          url: `/events/${eventId}`,
          query: { ignoreWarnings },
        }),
        method: 'DELETE',
      }),
      invalidatesTags: [
        { type: 'Event', id: 'LIST' },
        { type: 'EventSample', id: 'LIST' },
      ],
    }),
    registerToEvent: builder.mutation<WithRawDates<StoredEventEntry>, RegisterToEventArgs>({
      query: ({ eventId, slotIdx, toRegister, type }) => {
        const url = queryString.stringifyUrl({
          url: `/events/${eventId}/${type}/` + toRegister._id,
          query: { slotIdx },
        });
        return {
          url,
          body: toRegister,
          method: 'POST',
        };
      },
      invalidatesTags: event => [
        { type: 'Event', id: event?._id },
        { type: 'EventSample', id: event?._id },
        { type: 'EventHistory', id: event?._id },
      ],
    }),
    forceRegisterToEvent: builder.mutation<
      WithRawDates<StoredEventEntry>,
      ForceRegisterToEventArgs
    >({
      query: ({ eventId, toRegisters = [], slotIdx, type }) => {
        return {
          url: queryString.stringifyUrl({
            url: `/events/${eventId}/force_register/${type}`,
            query: { slotIdx },
          }),
          body: toRegisters,
          method: 'POST',
        };
      },
      invalidatesTags: event => [
        { type: 'Event', id: event?._id },
        { type: 'EventSample', id: event?._id },
        { type: 'EventHistory', id: event?._id },
      ],
    }),
    forceRegisterUsersWithMetadataToEvent: builder.mutation<
      WithRawDates<StoredEventEntry>,
      ForceRegisterUsersWithMetadata
    >({
      query: ({ eventId, ...forceRegisterArgs }) => {
        return {
          url: queryString.stringifyUrl({
            url: `/events/${eventId}/force_register/users`,
          }),
          body: forceRegisterArgs,
          method: 'POST',
        };
      },
      invalidatesTags: event => [
        { type: 'Event', id: event?._id },
        { type: 'EventSample', id: event?._id },
        { type: 'EventHistory', id: event?._id },
      ],
    }),
    unregisterFromEvent: builder.mutation<WithRawDates<StoredEventEntry>, RegisterToEventArgs>({
      query: ({ eventId, slotIdx, toRegister, type }) => {
        return {
          url: queryString.stringifyUrl({
            url: `/events/${eventId}/${type}/${toRegister._id}`,
            query: { slotIdx },
          }),
          method: 'DELETE',
        };
      },
      invalidatesTags: event => [
        { type: 'Event', id: event?._id },
        { type: 'EventSample', id: event?._id },
        { type: 'EventHistory', id: event?._id },
      ],
    }),
    forceUnregisterFromEvent: builder.mutation<
      WithRawDates<StoredEventEntry>,
      ForceRegisterToEventArgs
    >({
      query: ({ eventId, slotIdx, toRegisters, type }) => {
        return {
          url: queryString.stringifyUrl({
            url: `/events/${eventId}/force_register/${type}`,
            query: { slotIdx },
          }),
          body: toRegisters,
          method: 'DELETE',
        };
      },
      invalidatesTags: event => [
        { type: 'Event', id: event?._id },
        { type: 'EventSample', id: event?._id },
        { type: 'EventHistory', id: event?._id },
      ],
    }),
    forceUnregisterUsersWithMetadataFromEvent: builder.mutation<
      WithRawDates<StoredEventEntry>,
      ForceRegisterUsersWithMetadata
    >({
      query: ({ eventId, ...forceUnregisterArgs }) => {
        return {
          url: queryString.stringifyUrl({
            url: `/events/${eventId}/force_register/users`,
          }),
          body: forceUnregisterArgs,
          method: 'DELETE',
        };
      },
      invalidatesTags: event => [
        { type: 'Event', id: event?._id },
        { type: 'EventSample', id: event?._id },
        { type: 'EventHistory', id: event?._id },
      ],
    }),
    forceRegisterRoomsToEvent: builder.mutation<
      WithRawDates<StoredEventEntry>,
      ForceRegisterRoomsToEventArgs
    >({
      query: ({ eventId, roomsToRegister, slotIdx }) => {
        const baseUrl = `/events/${eventId}/register/room`;
        const slot = `?slotIdx=${slotIdx}`;

        return {
          url: baseUrl + slot,
          body: roomsToRegister,
          method: 'POST',
        };
      },
      invalidatesTags: event => [
        { type: 'Event', id: event?._id },
        { type: 'EventSample', id: event?._id },
        { type: 'EventHistory', id: event?._id },
      ],
    }),
    massForceRegisterRoomsToEvent: builder.mutation<
      WithRawDates<StoredEventEntry>,
      MassForceRegisterRoomsToEventArgs
    >({
      query: ({ eventId, roomsToRegister, slotIdx }) => {
        const baseUrl = `/events/${eventId}/register/mass/room`;
        let slot = '?';
        if (slotIdx && slotIdx?.length > 0) {
          slotIdx?.forEach((el, index) => {
            slot += `slotIdx[]=${el}`;
            if (index <= slotIdx.length - 1) {
              slot += '&';
            }
          });
        }

        return {
          url: baseUrl + slot,
          body: roomsToRegister,
          method: 'POST',
        };
      },
      invalidatesTags: event => [
        { type: 'Event', id: event?._id },
        { type: 'EventSample', id: event?._id },
        { type: 'EventHistory', id: event?._id },
      ],
    }),
    searchRooms: builder.query<RoomRef[], ISearchRoomsRequest>({
      query: ({
        search,
        city,
        limit = DEFAULT_PAGINATION['limit'],
        offset = DEFAULT_PAGINATION['offset'],
        disabled = false,
      }) =>
        queryString.stringifyUrl({
          url: '/rooms',
          query: {
            search,
            city,
            limit,
            offset,
            disabled,
            refOnly: true,
          },
        }),
      providesTags: rooms => providesList(rooms, 'RoomRef'),
    }),
    getRooms: builder.query<RoomEntry[], ISearchRoomsRequest>({
      query: ({
        search,
        limit = DEFAULT_PAGINATION['limit'],
        offset = DEFAULT_PAGINATION['offset'],
      }) =>
        queryString.stringifyUrl({
          url: '/rooms',
          query: {
            search,
            limit,
            offset,
            refOnly: false,
          },
        }),
      providesTags: rooms => providesList(rooms, 'Room'),
    }),
    searchRoomEvents: builder.query<
      WithRawDates<Stored<EventTimeline>>[],
      ISearchRoomEventsRequest
    >({
      query: ({
        start,
        end,
        search,
        limit = DEFAULT_PAGINATION['limit'],
        offset = DEFAULT_PAGINATION['offset'],
      }) =>
        queryString.stringifyUrl({
          url: '/rooms/events',
          query: {
            start,
            end,
            search,
            limit,
            offset,
          },
        }),
      providesTags: events => providesList(events, 'Event'),
    }),
    unregisterRoomToSlot: builder.mutation<
      WithRawDates<StoredEventEntry>,
      UnregisterRoomFromSlotArgs
    >({
      query: ({ eventId, slotIdx }) => {
        const baseUrl = `/events/${eventId}/register/room`;
        let slot = '';
        if (slotIdx !== undefined) slot = `?slotIdx=${slotIdx}`;

        return {
          url: baseUrl + slot,
          method: 'DELETE',
        };
      },
      invalidatesTags: event => [
        { type: 'Event', id: event?._id },
        { type: 'EventSample', id: event?._id },
        { type: 'EventHistory', id: event?._id },
      ],
    }),
    massUnregisterRoomToSlot: builder.mutation<
      WithRawDates<StoredEventEntry>,
      MassUnregisterRoomFromSlotArgs
    >({
      query: ({ eventId, slotIdx }) => {
        const baseUrl = `/events/${eventId}/register/mass/room`;
        let slot = '?';
        if (slotIdx && slotIdx?.length > 0) {
          slotIdx?.forEach((el, index) => {
            slot += `slotIdx[]=${el}`;
            if (index <= slotIdx.length - 1) {
              slot += '&';
            }
          });
        }

        return {
          url: baseUrl + slot,
          method: 'DELETE',
        };
      },
      invalidatesTags: event => [
        { type: 'Event', id: event?._id },
        { type: 'EventSample', id: event?._id },
        { type: 'EventHistory', id: event?._id },
      ],
    }),
  }),
});

export const {
  useGetEventQuery,
  useLazyGetEventQuery,
  useGetEventHistoryQuery,
  useLazyGetEventHistoryQuery,
  useGetEventsWithRegisteredStatusQuery,
  useLazyGetEventsWithRegisteredStatusQuery,
  useSearchEventsQuery,
  useSearchEventsWithModuleIdsQuery,
  useGetRoomsQuery,
  useSearchRoomEventsQuery,
  useSearchEventsWithRoomsByCityIdQuery,
  useLazySearchEventsQuery,
  useSearchRoomsQuery,
  useLazySearchRoomsQuery,
  useGetEventsQuery,
  useLazyGetEventsQuery,
  useCreateEventMutation,
  useUpdateEventMutation,
  useDeleteEventMutation,
  useRegisterToEventMutation,
  useForceRegisterToEventMutation,
  useForceRegisterUsersWithMetadataToEventMutation,
  useUnregisterFromEventMutation,
  useForceUnregisterFromEventMutation,
  useForceUnregisterUsersWithMetadataFromEventMutation,
  useUnregisterRoomToSlotMutation,
  useMassUnregisterRoomToSlotMutation,
  useForceRegisterRoomsToEventMutation,
  useMassForceRegisterRoomsToEventMutation,
} = eventsApi;
