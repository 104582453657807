import { Flex, IconButton, Progress } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

interface PageIndicatorProps {
  onPrevClick: () => void;
  onNextClick: () => void;
  page: number;
  maxPages: number;
}

function _PageIndicator({onPrevClick, onNextClick, page, maxPages}: PageIndicatorProps) {
  return (
    <Flex
      mt={5}
      mb={0} 
      width="35vw"
      justifyContent="center" 
      mx="auto"
    >
      <IconButton
        as={motion.button}
        whileTap={{ scale: 1.5 }}
        aria-label="prev"
        icon={<FiChevronLeft/>}
        ml={2}
        mr={2}
        onClick={onPrevClick}
        />
      <Progress
        as={motion.div}
        transition='1s ease-in'
        value={100 / ((maxPages - 1) / page)}
        alignSelf="center"
        borderRadius="2px"
        flex={1}
        bgColor="primaryBgColor"
        colorScheme="gray"
        h="3px"
        sx={{
          "> div": {
            transitionProperty: 'width',
          }
        }}
      />
      <IconButton
        as={motion.button}
        whileTap={{ scale: 1.5 }}
        aria-label="next"
        icon={<FiChevronRight/>}
        ml={2}
        mr={2}
        onClick={onNextClick}
      />
    </Flex>
  );
}

export const PageIndicator = React.memo(_PageIndicator);
