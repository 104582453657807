import { Badge, useColorMode, useMediaQuery } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React from "react";

import { StatusBadge } from "./IconStatusBadge";

interface RoomStatusBadgeProps {
  occupiedText: string;
  freeText: string;
  isOccupied: boolean;
}

function _RoomStatusBadge({occupiedText, freeText, isOccupied}: RoomStatusBadgeProps) {
    const [ shouldRenderAsPellet ] = useMediaQuery("(max-width: 90rem)")
    const text = isOccupied ? occupiedText : freeText
    const badgeVariant = isOccupied ? "error" : "success"
    const {colorMode} = useColorMode()

    return (
      <>
        {
        shouldRenderAsPellet ?
        <StatusBadge colorMode={colorMode} variant={badgeVariant} />
        :
        <Badge 
          variant={badgeVariant}
          borderRadius={20} 
          padding="0.8rem" 
          colorScheme={colorMode}
          fontSize="0.9rem"
          as={motion.div}
          transition="0.2 ease-in"
          whileHover={{scale: 0.9}}
        >
          {text}
        </Badge>
      }
    </>
  );
}

export const RoomStatusBadge = React.memo(_RoomStatusBadge);
