import { Badge, Icon } from "@chakra-ui/react";
import React from "react";
import { GoDotFill } from "react-icons/go";
interface StatusBadgeProps {
  variant: string;
  colorMode: string;
}

function _StatusBadge({variant, colorMode}: StatusBadgeProps) {
  return (
    <Badge borderRadius={100} padding="0.3rem" colorScheme={colorMode} variant={variant} display="flex">
      <Icon as={GoDotFill}/>
    </Badge>
  );
}

export const StatusBadge = React.memo(_StatusBadge);
