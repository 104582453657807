import { Box, Flex, Text } from '@chakra-ui/react';
import { CohortRef } from '@epitech/ops-panoramix-types';
import { formatDate } from '@fullcalendar/core';
import { MultiValue, SingleValue } from 'chakra-react-select';
import { addHours, subHours } from 'date-fns';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { ButtonDatePicker } from '@/components/ui-elements/DatePicker/ButtonDatePicker';
import { AsyncSelect } from '@/components/ui-elements/Select/Async';
import { Select } from '@/components/ui-elements/Select/Select';
import { IOptionGen } from '@/components/ui-elements/Select/types';
import { getCohortOption } from '@/components/users';
import { useSearchRoomsQuery } from '@/store/services/events';

import { getRoomOption, IRoomOption, RoomMultiValue, RoomOptionMultiValue } from '../select';
import { useRoomSearch } from '../useRoomSearch';

interface RoomStatusFilterProps {
  city: IOptionGen<string> | undefined;
  cohorts: CohortRef[];
}

function _RoomStatusFilter({ city, cohorts }: RoomStatusFilterProps) {
  const { t: tdate } = useTranslation('layouts/calendar/Filters');
  const { t: troom } = useTranslation('components/event-form');
  const { t } = useTranslation('components/rooms/cards');

  const [searchParams, setSearchParams] = useSearchParams();
  const cityParam = searchParams.get('city');
  const roomsParam = searchParams.get('rooms');
  const fromParam = searchParams.get('start');
  const toParam = searchParams.get('end');

  const searchRooms = useRoomSearch({ city: city?.label || '' });
  const { data: cityRooms, isLoading } = useSearchRoomsQuery({ city: city?.label, limit: 0 });

  const defaultRooms = useMemo(() => {
    const defaultRooms = cityRooms?.filter(el => roomsParam?.split(',').includes(el._id));
    return defaultRooms?.map(getRoomOption);
  }, [roomsParam, cityRooms]);

  const onCityChange = useCallback(
    async (newValue: SingleValue<IOptionGen<string>>) => {
      if (newValue === null) {
        setSearchParams(prev => {
          prev.set('city', '');
          return prev;
        });
      }
      if (newValue?.value) {
        setSearchParams(prev => {
          prev.set('city', newValue?.label);
          return prev;
        });
      }
      setSearchParams(prev => {
        prev.set('rooms', '');
        return prev;
      });
      setSearchParams(prev => {
        prev.set('page', '0');
        return prev;
      });
    },
    [setSearchParams],
  );

  const onRoomsChange = useCallback(
    (newValue: MultiValue<IRoomOption>) => {
      if (newValue) {
        setSearchParams(prev => {
          prev.set('rooms', [...newValue.map(el => el.value._id)].toString());
          return prev;
        });
      } else {
        setSearchParams(prev => {
          prev.set('rooms', '');
          return prev;
        });
      }
      setSearchParams(prev => {
        prev.set('page', '0');
        return prev;
      });
    },
    [setSearchParams],
  );

  const onFromChange = useCallback(
    (value: Date) => {
      const newValue: Date = value;
      if (fromParam && value.getTime() > new Date(fromParam)?.getTime()) {
        const newEndValue = addHours(value, 1);
        setSearchParams(prev => {
          prev.set('end', newEndValue.toISOString());
          return prev;
        });
      }
      setSearchParams(prev => {
        prev.set('start', newValue.toISOString());
        return prev;
      });
    },
    [fromParam, setSearchParams],
  );

  const onToChange = useCallback(
    (value: Date) => {
      const newValue: Date = value;
      if (toParam && value.getTime() < new Date(toParam).getTime()) {
        const newStartValue = subHours(value, 1);
        setSearchParams(prev => {
          prev.set('start', newStartValue.toISOString());
          return prev;
        });
      }
      setSearchParams(prev => {
        prev.set('end', newValue.toISOString());
        return prev;
      });
    },
    [toParam, setSearchParams],
  );

  return (
    <Flex
      mb={4}
      justifyContent={{ base: 'space-between' }}
      flexDirection={{ base: 'column', md: 'column', xl: 'row' }}
      alignItems={{ base: 'flex-start', md: 'flex-start', xl: 'center' }}
    >
      <Box width={{ base: '100%', sm: '100%', md: '80%', xl: '30vw' }} alignItems={'space-between'}>
        <Select<IOptionGen<string>>
          name="researchCityRoomView"
          placeholder={tdate('searchCity')}
          size="sm"
          onChange={onCityChange}
          defaultValue={city}
          options={cohorts.map(getCohortOption)}
          htmlDecode={true}
          isClearable
          isSearchable
          menuPortalTarget={document.body}
          menuPosition="fixed"
          noOptionsMessage={() => tdate('search_cohort_no_options')}
        />
        {!isLoading && cityParam && (
          <AsyncSelect<IRoomOption, true>
            name="searchRoom"
            placeholder={tdate('searchRoom')}
            isClearable
            isMulti
            size="sm"
            defaultValue={defaultRooms}
            value={defaultRooms}
            loadOptions={searchRooms}
            onChange={onRoomsChange}
            noOptionsMessage={() => troom('search_room_no_options')}
            infiniteScroll={true}
            components={{ Option: RoomOptionMultiValue, MultiValue: RoomMultiValue }}
            chakraStyles={{
              container: provided => ({
                ...provided,
                mt: 4,
              }),
            }}
          />
        )}
      </Box>
      <Box display="flex" flexDirection={{ base: 'column', sm: 'column', md: 'row', xl: 'column' }}>
        <Flex flexDirection="row" alignItems={'center'}>
          <Text>{t('from')}</Text>
          {fromParam && (
            <ButtonDatePicker
              selected={new Date(fromParam || '')}
              title={'Select date'}
              buttonTitle={formatDate(fromParam, {
                locale: tdate('language'),
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              })}
              onChange={onFromChange}
              showTimeSelect
            />
          )}
        </Flex>
        <Flex flexDirection="row" alignItems={'center'}>
          <Text>{t('to')}</Text>
          {toParam && (
            <ButtonDatePicker
              selected={new Date(toParam || '')}
              title={'Select date'}
              buttonTitle={formatDate(toParam, {
                locale: tdate('language'),
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              })}
              onChange={onToChange}
              showTimeSelect
            />
          )}
        </Flex>
      </Box>
    </Flex>
  );
}

export const RoomStatusFilter = React.memo(_RoomStatusFilter);
