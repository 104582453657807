import { tagAnatomy as parts } from '@chakra-ui/anatomy';
import { theme } from '@chakra-ui/react';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  container: {
    whiteSpace: 'nowrap',
  },
});

const variants = {
  moduleCode: definePartsStyle(props => ({
    container: {
      ...theme.components.Badge.variants?.subtle(props),
      fontFamily: 'mono',
      color: 'blueGreenFontColor',
      bgColor: 'greyLightDarkBgColor',
    },
  })),
  roundXs: definePartsStyle(props => ({
    container: {
      ...theme.components.Badge.variants?.subtle(props),
      borderRadius: 20,
      fontSize: 'xs',
      mr: 2,
      width: 'fit-content',
    },
  })),
};

export const Tag = defineMultiStyleConfig({
  variants,
  baseStyle,
  defaultProps: {
    colorScheme: 'teal',
  },
});
