import { ComponentStyleConfig } from '@chakra-ui/react';

export const Text: ComponentStyleConfig = {
  baseStyle: {
    fontFamily: 'Open Sans',
  },
  variants: {},
  defaultProps: {
    color: 'primaryFontColor',
  },
};
