import { cardAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

export const Card = defineMultiStyleConfig({
  baseStyle: {
    container: {
      backgroundColor: 'cardBgColor',
    },
  },
});
