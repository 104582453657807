import { Card, CardBody, CardFooter, CardHeader, Flex, Heading, Text } from "@chakra-ui/react"
import React from "react";
import { useTranslation } from "react-i18next";

import { useRoomStatus } from "../../../../lib/hooks/useRoomStatus";
import { RoomStatusBadge } from "../../../ui-elements/Badge/StatusBadge";
import { Can } from "../../../ui-elements/Can";
import { RoomStatusItemProps } from "../RoomStatusContainer";
import { RoomStatusText } from "../RoomStatusText";

export function _RoomGridItem({name, events, nowTimeStamp}: RoomStatusItemProps) {
  const { t } = useTranslation('components/events');
  const { t: tcard } = useTranslation('components/rooms/cards');
  const { isReserved, isOccupied, onGoingEvent, consecutiveEvents, onGoingIndex } = useRoomStatus(events, nowTimeStamp);

  return (
    <Card borderRadius={5}> 
      <CardHeader display="flex" flexDirection="row" justifyContent="space-between" alignItems="start">
        <Heading size='sm' mr="1rem" overflow="hidden">{name}</Heading>
        <RoomStatusBadge
          occupiedText={tcard("occupied")}
          freeText={tcard("free")}
          isOccupied={isOccupied}
        />
      </CardHeader>
      <CardBody mt={0} pt={0}>
      {
        isReserved &&
        <RoomStatusText
          isOccupied={isOccupied}
          currentEvent={onGoingEvent}
          nextEvent={consecutiveEvents.length - 1 > 0 ? consecutiveEvents[consecutiveEvents.length - 1] : null}
          locale={t("language")}
        />
      }
      </CardBody>
      <CardFooter>
      {
        isReserved && onGoingIndex >= 0 &&
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          <Can I="update" an="event">
            <Text>
              {onGoingEvent.title}
            </Text>
          </Can>
        </Flex>
      }
      </CardFooter>
    </Card>
  );
}

export const RoomGridItem = React.memo<RoomStatusItemProps>(_RoomGridItem);
