import { ComponentStyleConfig, defineStyle } from '@chakra-ui/react';

const variants = {
  error: defineStyle(({ colorScheme }) => {
    switch (colorScheme) {
      case 'dark':
        return {
          boxShadow: '0 0 10px 3px hsla(358, 66%, 48%, 1)',
          bgColor: 'errorStatusBadgeBgColor',
          color: 'errorStatusBadgeFgColor',
        };
      case 'light':
        return {
          boxShadow: '0 0 10px 3px hsla(0, 100%, 96%, 1)',
          bgColor: 'errorStatusBadgeBgColor',
          color: 'errorStatusBadgeFgColor',
        };
      default:
        return {};
    }
  }),

  success: defineStyle(({ colorScheme }) => {
    switch (colorScheme) {
      case 'dark':
        return {
          boxShadow: '0 0 10px 3px hsla(133, 50%, 32%, 1)',
          bgColor: 'successStatusBadgeBgColor',
          color: 'successStatusBadgeFgColor',
        };
      case 'light':
        return {
          boxShadow: '0 0 10px 3px hsla(120, 60%, 95%, 1)',
          bgColor: 'successStatusBadgeBgColor',
          color: 'successStatusBadgeFgColor',
        };
      default:
        return {};
    }
  }),
};

export const Badge: ComponentStyleConfig = {
  baseStyle: {
    color: 'primaryFontColor',
    font: 'Open Sans',
  },
  variants,
};
