import { Flex, ListItem, Spacer, Text, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { useRoomStatus } from "../../../../lib/hooks/useRoomStatus";
import { RoomStatusBadge } from "../../../ui-elements/Badge/StatusBadge";
import { RoomStatusItemProps } from "../RoomStatusContainer";
import { RoomStatusText } from "../RoomStatusText";

function _RoomListItem({name, events, nowTimeStamp}: RoomStatusItemProps): React.ReactElement {
  const [ isOnLowResolutionScreen ] = useMediaQuery("(max-width: 90rem)")
  const { t } = useTranslation('components/events');
  const { t: tcard } = useTranslation('components/rooms/cards');
  const {isReserved, isOccupied, onGoingEvent, consecutiveEvents } = useRoomStatus(events, nowTimeStamp)

  return (
    <ListItem display="flex" justifyContent="space-between" alignItems="center">
      {
        isOnLowResolutionScreen ?
        <>
          <Flex flexDirection="column">
            <Text
              flex="1"
            >
              {name}
            </Text>
            {
            isReserved ?
            <RoomStatusText
              isOccupied={isOccupied}
              currentEvent={onGoingEvent}
              nextEvent={consecutiveEvents.length - 1 > 0 ? consecutiveEvents[consecutiveEvents.length - 1] : null}
              locale={t("language")}
            /> : <Spacer/>
            } 
          </Flex>
          <RoomStatusBadge
            occupiedText={tcard("occupied")}
            freeText={tcard("free")}
            isOccupied={isOccupied}
          />
        </> :
        <>
          <Text
            flex="1"
            ml={5}
          >
            {name}
          </Text>
          <RoomStatusBadge
            occupiedText={tcard("occupied")}
            freeText={tcard("free")}
            isOccupied={isOccupied}
          />
          {
          isReserved ?
          <RoomStatusText
            ml="1rem"
            isOccupied={isOccupied}
            currentEvent={onGoingEvent}
            nextEvent={consecutiveEvents.length - 1 > 0 ? consecutiveEvents[consecutiveEvents.length - 1] : null}
            locale={t("language")}
          /> : <Spacer/>
          } 
        </>
      }
    </ListItem>
  );
}

export const RoomListItem = React.memo<RoomStatusItemProps>(_RoomListItem);
