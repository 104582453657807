export const datepickerStyle = {
  '.react-datepicker, .react-datepicker__time-list': {
    bgColor: 'modalBgColor',
  },
  '.react-datepicker__current-month, .react-datepicker__time-list, .react-datepicker__day-names .react-datepicker__day-name, .react-datepicker-time__header, .react-datepicker__day':
    {
      color: 'datepickerColor',
    },
  '.react-datepicker__day--disabled': {
    color: '#838383',
  },
  '.react-datepicker__navigation--previous': {
    borderRightColor: 'borderSpecialColor',
    _hover: {
      borderRightColor: 'borderSpecialHoverColor',
    },
  },
  '.react-datepicker__navigation--next': {
    borderLeftColor: 'borderSpecialColor',
    _hover: {
      borderLeftColor: 'borderSpecialHoverColor',
    },
  },
  '.react-datepicker__header': {
    bgColor: 'bgHeaderColor',
    borderColor: 'borderColor',
  },
  '.react-datepicker__time-container': {
    borderColor: 'borderColor',
    mr: '1px',
    '.react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list': {
      'li.react-datepicker__time-list-item:hover': {
        bgColor: 'bgHoverColor',
      },
      'li.react-datepicker__time-list-item--selected:hover': {
        bgColor: 'bgSelectedHoverColor',
      },
    },
  },

  '.react-datepicker__day:hover': {
    bgColor: 'bgHoverColor',
  },
  '.react-datepicker__day--selected:hover': {
    bgColor: 'bgSelectedHoverColor',
  },
  '.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected':
    {
      bgColor: 'bgSelectedHoverColor',
      fontWeight: 'normal',
    },
  '.react-datepicker': {
    fontFamily: 'unset',
    display: 'flex',
    fontSize: '0.9rem',
  },
  '.react-datepicker-wrapper, .react-datepicker__input-container': {
    backgroundColor: 'transparent',
    display: 'block',
  },
  '.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button)':
    {
      right: '90px',
    },
  '.react-datepicker__navigation--previous, .react-datepicker__navigation--next': {
    height: '32px',
    top: '8px',
  },
  '.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header':
    {
      fontSize: 'inherit',
      fontWeight: 600,
    },
  '.react-datepicker__time-container, .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box':
    {
      width: 'auto',
    },
  '.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list::-webkit-scrollbar':
    {
      display: 'none',
    },
  '.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list':
    {
      fontFamily: 'mono',
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
    },
  '.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item':
    {
      margin: '0 1px 0 0',
      height: 'auto',
      padding: '7px 10px',
    },
  '.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled':
    {
      display: 'none',
    },
  '.react-datepicker__close-icon::after': {
    backgroundColor: 'unset',
    borderRadius: 'unset',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: 'hsl(0, 0%, 80%)',
    height: '20px',
    width: '20px',
  },
  '.react-datepicker__close-icon::after:hover': {
    color: 'hsl(0, 0%, 70%)',
  },
};
